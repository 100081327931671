/**
 * Site header
 */
.site-header {
  border-top: 5px solid $grey-color-light;
  border-bottom: 1px solid $grey-color-dark;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2.25;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-color-dark;
  }
}

/*
*.site-nav {
*  float: right;
*  line-height: $base-line-height * $base-font-size * 2.25;
*
*  .nav-trigger {
*      display: none;
*  }
*
*  .menu-icon {
*    display: none;
*  }
*
*  .page-link {
*    color: $text-color;
*    line-height: $base-line-height;
*
*    // Gaps between nav items, but not on the last one
*    &:not(:last-child) {
*      margin-right: 20px;
*    }
*  }
*
*  @include media-query($on-palm) {
*    position: absolute;
*    top: 9px;
*    right: $spacing-unit / 2;
*    background-color: $background-color;
*    border: 1px solid $grey-color-light;
*    border-radius: 5px;
*    text-align: right;
*
*    label[for="nav-trigger"] {
*      display: block;
*      float: right;
*      width: 36px;
*      height: 36px;
*      z-index: 2;
*      cursor: pointer;
*    }
*
*    .menu-icon {
*      display: block;
*      float: right;
*      width: 36px;
*      height: 26px;
*      line-height: 0;
*      padding-top: 10px;
*      text-align: center;
*
*      > svg {
*        fill: $grey-color-dark;
*      }
*    }
*
*    input ~ .trigger {
*      clear: both;
*      display: none;
*    }
*
*    input:checked ~ .trigger {
*      display: block;
*      padding-bottom: 5px;
*    }
*
*    .page-link {
*      display: block;
*      padding: 5px 10px;
*
*      &:not(:last-child) {
*        margin-right: 0;
*      }
*      margin-left: 20px;
*    }
*  }
*}*/



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $grey-color-dark;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.svg-icon {
  height: 50px;
  width: 50px;
}
.footer-wrapper {
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
